<div
    *transloco="let t"
    id="top"
    class="focus-visible:outline-hidden"
    tabindex="-1">
    <p class="hidden">{{ t('a11yTabNav.directLinks') }}</p>
    <ul>
        @for (tablink of a11yTabNavService.navLinks; track $index) {
            <li [ngClass]="{ 'lg:hidden': tablink.viewPort === 'mobile', 'hidden lg:block': tablink.viewPort === 'desktop' }">
                <button
                    [title]="t(tablink.title)"
                    role="button"
                    (click)="focusItem(tablink.key)"
                    class="fixed -top-[99999px] focus:top-0"
                    [ngClass]="isTopBarShown() ? 'text-white' : 'text-body dark:text-white'">
                    {{ t(tablink.displayValue) }}
                </button>
            </li>
        }
    </ul>
</div>
