<ng-template #introText>
    @if (introductionText) {
        <div class="grid grid-cols-12 gap-6">
            <div
                [ngClass]="{
                    'hidden h-0 md:block md:opacity-0': !isFirstFormPage(),
                }"
                class="mx-8 mb-4 text-center lg:mb-11 md:mx-0 col-span-full md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3"
                [innerHTML]="introductionText | sanitize: securityContext"
                bigSharedComputeInnerHtmlLinks></div>
        </div>
    }
</ng-template>

@if (!isFormShown()) {
    <div class="mb-4 print:hidden">
        @if (showPageTitle && !showWizard()) {
            <big-ui-page-title [model]="node?.pageTitle" />
        }
    </div>
    @if (node) {
        <div class="container hide-in-mobile-app print:hidden">
            @if (node.breadcrumbs) {
                <big-ui-breadcrumb
                    class="mb-[2.125rem] md:mb-[4.625rem]"
                    [entries]="node.breadcrumbs" />
            }
            @if (!showWizard() && hasIntroduction) {
                <div class="grid grid-cols-12">
                    <div class="col-span-12 lg:col-start-2 lg:col-span-10">
                        <big-ui-introduction
                            [textCentered]="textCentered"
                            [model]="node"
                            [ngClass]="{
                                'mb-20': !node.newsIntroductionImage,
                                'mb-6': node.newsIntroductionImage,
                            }" />

                        @if (node.newsIntroductionImage) {
                            <big-ui-picture
                                class="block mb-8"
                                [model]="node.newsIntroductionImage" />
                        }
                    </div>
                </div>
            }
        </div>
    }
} @else {
    <big-nav-form-header [heading]="node?.heading">
        <ng-template [ngTemplateOutlet]="introText"></ng-template>
    </big-nav-form-header>
}

<div
    [ngClass]="{
        'container grid grid-cols-12 gap-6': showWizard(),
        '[&_big-form-holder]:container': !showWizard(),
    }">
    @if (showWizard()) {
        <div class="hidden col-span-3 md:block">
            <big-form-wizard class="mb-16" />
        </div>
    }
    <main
        [ngClass]="{
            'col-span-12 md:col-start-5 md:col-span-8': showWizard(),
        }">
        @if (node?.paragraphs) {
            <big-paragraphs-holder
                [data]="node.paragraphs"
                [ngClass]="{ 'noMarginTop': showWizard() }" />
        }
    </main>
</div>
