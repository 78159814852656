import { inject, Injectable } from '@angular/core';
import { JsonApiClientService, ResourceTypes as JsonApiResourceTypes } from '@big-direkt/json-api-client';
import { type MenuModel } from '@big-direkt/utils/shared';
import { firstValueFrom, Observable } from 'rxjs';
import { FooterMenuModel } from '../models/footer-menu.model';
import { HeaderMenuModel } from '../models/header-menu.model';
import { FooterMenuTypes, HeaderMenuTypes } from '../models/menu.types';

@Injectable({
    providedIn: 'root',
})
export class NavService {
    private readonly jsonApiClientService = inject(JsonApiClientService);

    public headerMenus?: HeaderMenuModel;
    public footerMenus?: FooterMenuModel;

    public async initHeaderMenus(menus: { name: keyof HeaderMenuModel; type: HeaderMenuTypes }[]): Promise<void> {
        this.headerMenus = (await this.getMenus(menus)) as HeaderMenuModel;
    }

    public async initFooterMenus(menus: { name: keyof FooterMenuModel; type: FooterMenuTypes }[]): Promise<void> {
        this.footerMenus = (await this.getMenus(menus)) as FooterMenuModel;
    }

    private async getMenus(
        menus: { name: keyof FooterMenuModel | keyof HeaderMenuModel; type: FooterMenuTypes | HeaderMenuTypes }[],
    ): Promise<FooterMenuModel | HeaderMenuModel> {
        const observables: Observable<MenuModel>[] = [];

        menus.forEach(menu => {
            observables.push(this.jsonApiClientService.load(menu.type, JsonApiResourceTypes.Menu));
        });

        const resolvedMenus = {} as Record<string, MenuModel>;

        for (let index = 0; index < menus.length; index++) {
            const menu = menus[index];

            try {
                resolvedMenus[menu.name] = await firstValueFrom(observables[index]);
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
            } catch (_e: unknown) {
                // eslint-disable-next-line no-console
                console.error('not able to load menu', menu.name);
            }
        }

        return resolvedMenus;
    }
}
