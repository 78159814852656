<ul
    class="flex justify-start gap-6 mb-0 font-medium list-none md:justify-end xl:gap-10 font-ci"
    [ngClass]="wrapperClass">
    @for (item of menu?.items; track $index) {
        @if (item.type === 'darkmode_switch' && isDarkmodeEnabled()) {
            <li>
                <big-ui-darkmode-switch>
                    <ng-container
                        [ngTemplateOutlet]="itemTemplate"
                        [ngTemplateOutletContext]="{ item, icon: iconMap | iconMapToIcon: item.icon }" />
                </big-ui-darkmode-switch>
            </li>
        } @else if (item.type !== 'darkmode_switch') {
            <li>
                <big-ui-link
                    (click)="itemClicked.emit()"
                    [href]="item.uri"
                    [linkClass]="'no-underline text-gray-100 dark:text-white'">
                    <ng-container
                        [ngTemplateOutlet]="itemTemplate"
                        [ngTemplateOutletContext]="{ item, icon: iconMap | iconMapToIcon: item.icon }" />
                </big-ui-link>
            </li>
        }
    }
</ul>
