<nav
    *transloco="let t; prefix: 'nav'"
    aria-label="User"
    class="relative">
    <div
        class="hidden md:block"
        #origin>
            <big-ui-button
                    [hidden]="!isLoggedIn()"
                    [type]="'button'"
                    [buttonStyle]="'primary'"
                    [classList]="['items-center', 'h-8', 'gap-2', 'min-w-28', 'pl-1', 'pr-3']"
                    [id]="'myBigMenuDropDownLoggedIn'"
                    (clicked)="onUserMenuButtonClick(origin, navUser)">
                <div class="w-6 aspect-square border-white text-white border rounded-full text-[12px] leading-6 bg-primary-600">
                    <span class="block translate-y-[1px]">
                        {{ userProfile()?.name?.initials }}
                    </span>
                </div>
                <span class="sr-only">{{ t('logged-in') }}</span>
                <span>{{ loggedInText }}</span>
            </big-ui-button>

            <big-ui-button
                    [hidden]="isLoggedIn()"
                    [type]="'button'"
                    [buttonStyle]="'primary'"
                    [classList]="['items-center', 'h-8', 'gap-2', 'min-w-28']"
                    [id]="'myBigMenuDropDownLoggedOut'"
                    (clicked)="onUserMenuButtonClick(origin, navUser)">
                <span>{{ loggedOutText }}</span>
            </big-ui-button>
    </div>
</nav>

<ng-template #navUser>
    <big-nav-user
        (menuItemClicked)="closePopover()"
        cdkTrapFocus
        [cdkTrapFocusAutoCapture]="true"
        tabindex="0" />
</ng-template>
