import { Component, HostBinding, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ContentBaseComponent } from '@big-direkt/content';
import { UserRepository, UserService } from '@big-direkt/state/user';
import { UiIntroductionService } from '@big-direkt/ui/introduction';
import { SeoService } from '@big-direkt/utils/seo';
import { ExternalEventService, NavigateBackService, NodeTypes, type ResolvedRouteData } from '@big-direkt/utils/shared';

@Component({
    selector: 'big-content',
    templateUrl: './content.component.html',
    standalone: false,
})
export class ContentComponent extends ContentBaseComponent {
    public readonly userRepository = inject(UserRepository);
    public readonly userService = inject(UserService);
    public readonly externalEventService = inject(ExternalEventService);
    public readonly router = inject(Router);
    private readonly seoService = inject(SeoService);
    private readonly navigateBackService = inject(NavigateBackService);
    private readonly introductionService = inject(UiIntroductionService);

    @HostBinding('class') public classList = 'block';

    public showPageTitle!: boolean;
    public textCentered = false;
    public introductionText?: string;

    public get wizardPageCount(): number {
        return this.pageNavigationService.wizardPageCount;
    }

    protected updateContentDetails(data: ResolvedRouteData): void {
        const { privatePath } = this.environmentService;

        this.introductionService.setModel(this.node);

        if (privatePath) {
            this.textCentered = this.router.url.startsWith(privatePath) || this.node.type === NodeTypes.Error;
        }

        if (this.node.type === NodeTypes.Form && this.node.introduction !== undefined) {
            this.introductionText = data.content?.introduction;
        }

        this.showPageTitle = data.showPageTitle;

        if (data.content?.type !== NodeTypes.Form) {
            this.uiRepository.setIsFormShown(false);
        }

        this.seoService.initializeNode(this.node);

        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        this.navigateBackService.parentUri = this.node.breadcrumbs?.at(this.node.breadcrumbs.length - 2)?.uri;
    }
}
