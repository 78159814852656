@let isOpenState = isOpen();

<button
    [attr.id]="key"
    class="relative font-bold"
    [ngClass]="{ 'opacity-30': isOtherOpen() }"
    role="button"
    [attr.aria-label]="isOpen() ? closeLabel : label"
    (click)="toggleMobileNav()">
    @if (isOpenState) {
        <big-icon
            [icon]="iconClose"
            [size]="'w-6'"
            class="m-1 fill-primary dark:fill-dark-primary" />
    } @else {
        <big-icon
            [icon]="icon"
            [size]="'w-8'"
            class="fill-primary dark:fill-dark-primary" />
    }
    <ng-content />
</button>
