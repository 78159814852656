import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DocumentsService } from '@big-direkt/paragraphs/mybig-inbox';
import { UserService } from '@big-direkt/state/user';
import { UiLinkModule } from '@big-direkt/ui/link';
import { EnvironmentService } from '@big-direkt/utils/environment';
import { IconBigBoldLogout, IconComponent } from '@big-direkt/utils/icons';
import { MenuItemType } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';
import { NavService } from '../../services/nav.service';
import { DisplayDistributionPartnerIdPipe } from './display-distribution-partner-id.pipe';

@Component({
    selector: 'big-nav-user',
    templateUrl: './nav-user.component.html',
    imports: [IconComponent, NgClass, UiLinkModule, TranslocoDirective, DisplayDistributionPartnerIdPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavUserComponent {
    private readonly navService = inject(NavService);
    private readonly documentsService = inject(DocumentsService);
    private readonly userService = inject(UserService);
    private readonly environmentService = inject(EnvironmentService);

    public readonly logoutUrl = `${this.environmentService.privatePath}/logout`;
    public readonly iconLogout = IconBigBoldLogout;

    @Output() public readonly menuItemClicked = new EventEmitter();

    public menu = this.navService.headerMenus?.user;
    public profile = toSignal(this.userService.getProfile());
    public menuItemType: typeof MenuItemType = MenuItemType;
    public unreadDocumentsCount = this.documentsService.unread;

    public onMenuItemClick(): void {
        this.menuItemClicked.emit();
    }
}
