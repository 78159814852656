<ng-template
    #dropDownMenuInner
    let-data="data">
    <div
        cdkTrapFocus
        [cdkTrapFocusAutoCapture]="true"
        class="flex gap-2 p-10"
        tabindex="0">
        <ng-container
            [ngTemplateOutlet]="dropDownCol"
            [ngTemplateOutletContext]="{ side: 'left', item: data.item, index: data.index }" />

        @if (data.item.children.length > minPerColumn) {
            <ng-container
                [ngTemplateOutlet]="dropDownCol"
                [ngTemplateOutletContext]="{ side: 'right', item: data.item, index: data.index }" />
        }
    </div>
</ng-template>

<ng-template
    #dropDownCol
    let-side="side"
    let-item="item"
    let-index="index">
    <div
        class="flex flex-col gap-4"
        [ngClass]="{
            'w-1/2': item.children.length > minPerColumn
        }">
        @for (child of item.children | navChildBreakpointSort: minPerColumn : side; track i; let i = $index) {
            <a
                class="block no-underline"
                role="tab"
                (click)="onSubmenuLinkClick()"
                [href]="child?.uri"
                [routerLink]="child?.uri"
                [ngClass]="{
                    'active': child.uri === currentPath
                }">
                {{ child?.title }}
            </a>
        }
    </div>
</ng-template>

<nav
    aria-label="main"
    class="mt-1">
    <ul class="flex items-center m-0 gap-x-10">
        @for (item of menu?.items; track i; let i = $index) {
            <li>
                <button
                    #origin
                    class="font-ci whitespace-nowrap text-[1.125rem] text-primary hover:text-primary-light active:text-primary-light focus:text-primary-light dark:text-white dark:hover:text-primary-light dark:active:text-primary-light dark:focus:text-primary-light"
                    [ngClass]="{
                        'active': item.uri === currentPath
                    }"
                    (click)="onMenuHeaderItemClick(item, i, origin, dropDownMenuInner)">
                    {{ item.title }}
                </button>
            </li>
        }
    </ul>
</nav>
