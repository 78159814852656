import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject, type OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { FormService, MobileWizardComponent } from '@big-direkt/form/view';
import { MobileAppRepository } from '@big-direkt/state/mobile-app';
import { UiRepository } from '@big-direkt/state/ui';
import { UiButtonComponent } from '@big-direkt/ui/button';
import { ModalService } from '@big-direkt/ui/modal';
import { UiPageTitleComponent } from '@big-direkt/ui/page-title';
import { IconBigBoldSchliessen, IconComponent } from '@big-direkt/utils/icons';
import { BreakpointService, NavigateBackService, ResizedDirective, ResizedEvent, type PageTitleModel, type TypedSimpleChanges } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';
import { firstValueFrom } from 'rxjs';

// sadly mobile wizard uses page navigation service. That is missing any observable/signal features. Therefor we do not see if pages becoma active or inactive
// => no OnPush possible here in mobile-wizard
// => no OnPush possible here
@Component({
    selector: 'big-nav-form-header',
    templateUrl: './form-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [IconComponent, MobileWizardComponent, NgClass, UiPageTitleComponent, UiButtonComponent, TranslocoDirective, ResizedDirective],
})
export class FormHeaderComponent implements OnChanges {
    private readonly mobileAppRepository = inject(MobileAppRepository);
    private readonly breakpointService = inject(BreakpointService);
    private readonly uiRepository = inject(UiRepository);
    private readonly navigateBackService = inject(NavigateBackService);
    private readonly modalService = inject(ModalService);
    private readonly router = inject(Router);
    private readonly formService = inject(FormService);

    public readonly isEmbeddedInMobileApp = this.mobileAppRepository.isEmbeddedInMobileApp;
    public readonly isMobile = this.breakpointService.isMobile;
    public readonly isFormShown = this.uiRepository.isFormShown;

    @Input() public heading: string | undefined;

    public iconClose = IconBigBoldSchliessen;
    public pageTitle?: PageTitleModel;

    public ngOnChanges(changes: TypedSimpleChanges<FormHeaderComponent>): void {
        if (changes.heading) {
            this.pageTitle = {
                title: changes.heading.currentValue,
            };
        }
    }

    public onHeaderResized(event: ResizedEvent): void {
        this.breakpointService.headerSize.set({
            height: event.newRect.height,
            width: event.newRect.width,
        });
    }

    public async closeFormView(destination: 'back' | 'start'): Promise<void> {
        if (this.formService.currentForm.dirty) {
            const result = await firstValueFrom(this.modalService.showFormClose().afterClosed$);

            // data is potentially null on ssr
            // @see https://sentry.operations.big-osp.de/organizations/big/issues/16558
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (!result?.data?.leaveForm) {
                return;
            }
        }

        this.formService.clearFormValueState();

        await (destination === 'back' ? this.navigateBackService.goBackOrToParentPage() : this.router.navigateByUrl('/'));
    }
}
