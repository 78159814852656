import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'displayDistributionPartnerId',
})
export class DisplayDistributionPartnerIdPipe implements PipeTransform {
    public transform(id: string): string {
        return id.replace('-', ' - ');
    }
}
