import { A11yModule } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, Component, Input, effect, inject, input, type ElementRef, type TemplateRef } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { UserService } from '@big-direkt/state/user';
import { UiButtonComponent } from '@big-direkt/ui/button';
import { EnvironmentService } from '@big-direkt/utils/environment';
import { BreakpointService } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgOverlayContainerService, type NgPopoverRef } from 'ng-overlay-container';
import { NavUserComponent } from './nav-user.component';

@Component({
    selector: 'big-nav-user-button',
    templateUrl: './nav-user-button.component.html',
    imports: [UiButtonComponent, NavUserComponent, TranslocoDirective, A11yModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavUserButtonComponent {
    private readonly overlayContainerService = inject(NgOverlayContainerService);
    protected readonly breakpointService = inject(BreakpointService);
    private readonly router = inject(Router);
    private readonly userService = inject(UserService);
    private readonly environmentService = inject(EnvironmentService);

    public readonly privatePath = this.environmentService.privatePath;
    public readonly userProfile = toSignal(this.userService.getProfile());

    @Input({ required: true }) public loggedInText!: string;
    @Input({ required: true }) public loggedOutText!: string;

    public popoverRef?: NgPopoverRef;
    public isLoggedIn = input.required<boolean>();

    public constructor() {
        effect(() => {
            if (!this.breakpointService.isTablet() && !this.breakpointService.isDesktop()) {
                this.closePopover();
            }
        });
    }

    public async onUserMenuButtonClick(origin: HTMLElement, content: TemplateRef<ElementRef>): Promise<void> {
        if (!this.isLoggedIn()) {
            await this.router.navigateByUrl(this.privatePath);

            return;
        }

        this.popoverRef = this.overlayContainerService.open({
            content,
            origin,
            configuration: {
                panelClass: 'overflow-hidden',
                width: '300px',
                originX: 'end',
                originY: 'bottom',
                overlayX: 'end',
                overlayY: 'top',
                offsetY: 15,
                isResizable: false,
            },
        });
    }

    public closePopover(): void {
        this.popoverRef?.close();
    }
}
