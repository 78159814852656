<!-- TODO: Remove important exclamation marks when bootstrap is gone-->
<ng-template
    #quickNavItem
    let-item="item"
    let-icon="icon">
    <div class="flex items-center gap-1 text-sm hover:text-primary-light">
        @if (item.icon) {
            <big-icon
                [icon]="icon"
                [size]="'w-5'"
                [attr.alt]="item.title"
                class="fill-body dark:fill-white" />
        }
        @if (item.title === servicePhoneNumber.number) {
            <span class="sr-only">{{ 'nav.hotline' | transloco }}</span>
        }
        <span [ngClass]="{ 'max-[929px]:sr-only': item.type === 'darkmode_switch' || item.title === ('nav.contact' | transloco) }">{{ item.title }}</span>
        @if (item.title === servicePhoneNumber.number) {
            <span class="sr-only">{{ 'nav.call' | transloco }}</span>
        }
    </div>
</ng-template>

@if (menus) {
    <big-ui-header [centeredTitle]="centeredTitle()">
        <div
            secondaryHeaderContent
            class="flex items-center justify-between min-h-12 gap-6 min-[930px]:gap-10 xl:gap-20 print:hidden">
            <div class="grow-0">
                <big-nav-quicknav
                    [itemTemplate]="quickNavItem"
                    [id]="desktopHeaderToolsLink.key"
                    [menu]="menus.tools" />
            </div>
            @if (showSearch()) {
                <div class="flex-1">
                    <big-search-form
                        [location]="'header'"
                        [clearOnSubmit]="true" />
                </div>
            }
            @if (centeredTitle()) {
                <div class="mx-auto font-bold text-primary dark:text-white">
                    {{ centeredTitle() }}
                </div>
            }
            <div class="grow-0">
                <big-nav-quicknav
                    [itemTemplate]="quickNavItem"
                    [id]="desktopContactLink.key"
                    [menu]="menus.top" />
            </div>
        </div>
        @if (centeredTitle()) {
            <div
                centeredTitle
                class="px-3 py-2 text-center text-white transition duration-500 ease-in-out transform h-13 bg-primary md:hidden">
                {{ centeredTitle() }}
            </div>
        }
        <div
            primaryHeaderContent
            class="flex items-center justify-between gap-2 md:gap-10 lg:gap-3">
            <ng-content select="[navLogoContent]"></ng-content>

            <div class="hidden lg:block">
                @if (isUserLoggedIn() && !!menus.mainLoggedIn) {
                    <big-nav-main-desktop
                        [id]="desktopMenuLink.key"
                        [menu]="menus.mainLoggedIn" />
                } @else {
                    <big-nav-main-desktop
                        [id]="desktopMenuLink.key"
                        [menu]="menus.main" />
                }
            </div>

            <div class="flex-1 hidden md:block lg:hidden print:hidden"></div>

            <big-nav-user-button
                [isLoggedIn]="isUserLoggedIn()"
                [loggedInText]="headerContext().loggedInText"
                [loggedOutText]="headerContext().loggedOutText"
                class="print:hidden" />

            <div class="relative z-(--z-modal) lg:hidden print:hidden">
                <ul class="flex items-center justify-end gap-6 mb-0">
                    <li
                        class="md:hidden"
                        [ngClass]="{ 'opacity-30': isSidenavOpen() }">
                        <big-ui-link
                            [linkId]="mobileContactLink.key"
                            class="relative"
                            [href]="'/de/kontakt'"
                            [linkClass]="'relative flex font-bold'"
                            (click)="closeMobileNav()">
                            <span class="sr-only">{{ 'nav.contact' | transloco }}</span>
                            <big-icon
                                [icon]="iconPhone"
                                [size]="'w-8'"
                                class="fill-primary dark:fill-dark-primary" />
                        </big-ui-link>
                    </li>
                    @if (showSearch()) {
                        <li class="md:hidden">
                            <big-nav-mobile-icon-button
                                navType="search"
                                [label]="'nav.search.open' | transloco"
                                [closeLabel]="'nav.search.close' | transloco"
                                [icon]="iconSearch"
                                class="flex items-center" />
                        </li>
                    }
                    <li class="md:hidden">
                        <big-nav-mobile-icon-button
                            navType="user"
                            [label]="'nav.navigation.myBIG' | transloco"
                            [closeLabel]="'nav.navigation.close' | transloco"
                            [icon]="iconUser"
                            [class.flex]="isUserLoggedIn()"
                            [class.hidden]="!isUserLoggedIn()"
                            class="items-center">
                            @if (!isMobileUserNavOpen()) {
                                <span class="bg-redesign-success-600! rounded-full absolute top-0 -right-3 w-[18px] h-[18px] border-2 border-white"></span>
                            }
                            <span class="sr-only">{{ 'nav.logged-in' | transloco }}</span>
                        </big-nav-mobile-icon-button>

                        <big-ui-link
                            [ngClass]="{ 'opacity-30': isSidenavOpen() }"
                            [href]="privatePath"
                            [linkClass]="'relative block'"
                            [hidden]="isUserLoggedIn()"
                            (click)="closeMobileNav()">
                            <span class="sr-only">{{ 'nav.navigation.myBIG' | transloco }}</span>
                            <big-icon
                                [icon]="iconUser"
                                [size]="'w-8'"
                                class="fill-primary dark:fill-dark-primary" />
                        </big-ui-link>
                    </li>
                    <li>
                        <big-nav-mobile-icon-button
                            [key]="mobileMenuLink.key"
                            navType="nav"
                            [label]="'nav.navigation.main' | transloco"
                            [closeLabel]="'nav.navigation.close' | transloco"
                            [icon]="iconMenu"
                            class="flex items-center" />
                    </li>
                </ul>
            </div>
        </div>
        @if (isWizardVisible()) {
            <big-form-mobile-wizard class="container w-full px-6 pt-2 pb-1" />
        }
    </big-ui-header>
}
