import { A11yModule } from '@angular/cdk/a11y';
import { Location, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, Injector, Input, effect, inject, type ElementRef, type OnInit, type TemplateRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, RouterModule } from '@angular/router';
import { BreakpointService, HrefLinkDirective, MenuModel, type MenuItemModel } from '@big-direkt/utils/shared';
import { NgOverlayContainerService, type NgOverlayContainerConfiguration, type NgPopoverRef } from 'ng-overlay-container';
import { NavChildrenBreakpointSortPipe } from '../../pipes/nav-children-breakpoint-sort.pipe';

@Component({
    selector: 'big-nav-main-desktop',
    templateUrl: './nav-main-desktop.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [A11yModule, RouterModule, HrefLinkDirective, NavChildrenBreakpointSortPipe, NgClass, NgTemplateOutlet],
})
export class NavMainDesktopComponent implements OnInit {
    private readonly overlayContainerService = inject(NgOverlayContainerService);
    private readonly breakpointService = inject(BreakpointService);
    private readonly injector = inject(Injector);
    protected readonly destroyRef = inject(DestroyRef);
    private readonly location = inject(Location);
    private readonly router = inject(Router);

    public readonly minPerColumn = 8;

    @Input() public menu?: MenuModel;

    public currentPath!: string;
    public popoverRef?: NgPopoverRef;

    public ngOnInit(): void {
        this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((): void => {
            this.currentPath = this.location.path().replace('/', '');
        });

        effect(
            () => {
                if (!this.breakpointService.isDesktop()) {
                    this.popoverRef?.close();
                }
            },
            { injector: this.injector },
        );
    }

    public onMenuHeaderItemClick(item: MenuItemModel, index: number, origin: HTMLElement, content: TemplateRef<ElementRef>): void {
        if (item.children.length > 0) {
            const configuration: Partial<NgOverlayContainerConfiguration> = {
                panelClass: item.children.length <= this.minPerColumn ? 'nav-modal-single-column' : 'nav-modal-multi-column',
                originX: 'start',
                overlayX: 'start',
                offsetY: 15,
                isResizable: false,
                hasBackdrop: false,
                fallbackPositions: [
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                        offsetY: 15,
                    },
                ],
            };

            this.popoverRef = this.overlayContainerService.open<{ item: MenuItemModel; index: number }>({
                content,
                data: { item, index },
                origin,
                configuration,
            });

            this.popoverRef.overlay.outsidePointerEvents().pipe(
                takeUntilDestroyed(this.destroyRef),
            ).subscribe(() => {
                this.popoverRef?.close();
            });
        } else {
            void this.router.navigateByUrl(item.uri);
        }
    }

    public onSubmenuLinkClick(): void {
        this.popoverRef?.close();
    }
}
