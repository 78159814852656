import { CommonModule, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UiDarkmodeSwitchComponent } from '@big-direkt/ui/darkmode-switch';
import { UiLinkModule } from '@big-direkt/ui/link';
import { FeatureFlagsService } from '@big-direkt/utils/environment';
import {
    IconBigLightDarkmode,
    IconBigLightKontakt,
    IconBigLightLeichteSprache,
    IconBigLightTelefon24H,
    IconMapToIconPipe,
    type IconMap,
} from '@big-direkt/utils/icons';
import { MenuModel } from '@big-direkt/utils/shared';

@Component({
    selector: 'big-nav-quicknav',
    templateUrl: './nav-quicknav.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [IconMapToIconPipe, NgClass, RouterModule, UiLinkModule, UiDarkmodeSwitchComponent, CommonModule],
})
export class NavQuicknavComponent {
    private readonly featureFlagsService = inject(FeatureFlagsService);
    public readonly isDarkmodeEnabled = this.featureFlagsService.isFeatureEnabled('big-app-dark-mode');

    public readonly iconMap: IconMap = {
        /* eslint-disable @typescript-eslint/naming-convention */
        'big-light-darkmode': IconBigLightDarkmode,
        'big-light-kontakt': IconBigLightKontakt,
        'big-light-leichte-sprache': IconBigLightLeichteSprache,
        'big-light-telefon-24-h': IconBigLightTelefon24H,
        /* eslint-enable @typescript-eslint/naming-convention */
    };

    @Input() public wrapperClass = '';
    @Input({ required: true }) public menu!: MenuModel;
    @Input({ required: true }) public itemTemplate!: HTMLTemplateElement;

    @Output() public readonly itemClicked = new EventEmitter();
}
